import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonPrimary } from "../styled/ButtonPrimary";
import { getTranslated } from "../functions/getTranslatedLevel";

interface IPaqSubmitDialog {
  customMsg: string;
  handleModalClose: () => any;
  handleModalSubmit ?: () => any;
  isConfirm ?: boolean;
}
export default function PaqSubmitDialog({ customMsg = "", handleModalClose, handleModalSubmit, isConfirm = false }: IPaqSubmitDialog) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    handleModalClose && handleModalClose();
  };

  const handleSubmit = () => {
    setOpen(false);
    handleModalSubmit && handleModalSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <DialogContent>
        {customMsg !== "" && <DialogContentText style={{ color: "#000000f7" }} id="alert-dialog-description">
          {customMsg}
        </DialogContentText>}
      </DialogContent>
      {isConfirm ? <DialogActions>
        <ButtonPrimary
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={handleClose}
          autoFocus>
          {getTranslated('buttons.cancel') || 'Cancel'}
        </ButtonPrimary>
        <ButtonPrimary
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={handleSubmit}
          autoFocus>
           {getTranslated('buttons.OK') || 'OK'}
        </ButtonPrimary>
      </DialogActions> : <DialogActions>
        <ButtonPrimary
          style={{ marginRight: 10, marginBottom: 10 }}
          onClick={handleClose}
          autoFocus>
           {getTranslated('buttons.OK') || 'OK'}
        </ButtonPrimary>
      </DialogActions>}
    </Dialog>
  );
}
